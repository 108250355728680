.navbar {
  background-color: var(--pure-white-color);
  color: var(--text-color-light);
  //box-shadow: 0 2px 4px var(--sub-head-color-light);
  padding: 1rem 2rem;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1000;

  &__container {
    display: grid;
    align-items: center;
    grid-template-columns: 40% 40% 2%;
    padding: 0 0.5rem;
  }

  &__logo {
    font-family: var(--font-brand);
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--primary-color-light);
    gap:0.5rem;
    span{
        margin-top:0.25rem;
        letter-spacing: 0.075em;
    }
    
  }

  &__links {
    display: flex;
    gap: 1.5rem;
    width: 100%;

    a {
      text-decoration: none;
      color: var(--text-color-light);
      transition: color 0.3s, transform 0.3s, opacity 0.3s;
      position: relative;

      &:hover {
        color: var(--primary-color-light--hover);
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: var(--primary-color-light);
        transform: scaleX(0);
        transition: transform 0.3s ease;
        transform-origin: right;
      }

      &:hover::after {
        transform: scaleX(1);
        transform-origin: left;
      }
    }
    a.bold {
      font-weight: bold;
    }
    &.active {
      display: flex;
      position: absolute;
      top: 64px;
      right: 0.5rem;
      width: 40%;
      background-color: var(--primary-bg-light-mode);
      padding: 1rem 1.5rem;
      box-shadow: 0 2px 10px var(--sub-head-color-light);
      border-radius: 0.5rem;
      text-align: right;
      z-index: 1001;
      animation: slideDown 0.5s ease-out; // Fışt efekti burada
    }

    &__close {
      position: absolute;
      top: 1rem;
      right: 1rem;
      font-size: 1.5rem;
      color: var(--text-color-light);
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: var(--primary-color-light--hover);
      }
    }
  }

  &__toggle {
    display: none;
    background: none;
    border: none;
    color: var(--text-color-light);
    font-size: 1.5rem;
    cursor: pointer;

    &:hover {
      color: var(--primary-color-light--hover);
    }
  }

  &__profile {
    right: 1rem;

    &-toggle {
      background: none;
      border: none;
      color: var(--text-color-light);
      font-size: 1.5rem;
      cursor: pointer;

      &:hover {
        color: var(--primary-color-light--hover);
      }
    }

    &-menu {
      top: 50px;
      right: 0;
      flex-direction: column;
      gap: 0.5rem;
      background-color: var(--primary-bg-light-mode);
      box-shadow: 0 2px 10px var(--sub-head-color-light);
      border-radius: 0.5rem;
      padding: 0.5rem 1.2rem;
      z-index: 100;
      white-space: nowrap;
      opacity: 0; // Başlangıçta gizli
      transform: translateY(-20px); // Başlangıçta yukarıda
      animation: slideDown 0.5s ease-out forwards; // Fışt efekti burada

      a {
        text-decoration: none;
        color: var(--text-color-light);
        padding: 0.5rem 0;
        transition: color 0.3s;

        &:hover {
          color: var(--primary-color-light--hover);
        }
      }

      &__close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        font-size: 1.5rem;
        color: var(--text-color-light);
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
          color: var(--primary-color-light--hover);
        }
      }
    }
  }

  @media (max-width: 768px) {
    &__container {
      grid-template-columns: 80% 4% 4%;
      padding: 0 0.5rem;
    }

    &__links {
      display: none;
      flex-direction: column;
      width: 100%;
    }

    &__toggle {
      display: block;
    }

    &__profile-menu {
      right: 10px;
    }
  }

  &.dark-mode {
    background-color: var(--primary-bg-dark-mode);
    color: var(--text-color-dark);

    &__logo {
      color: var(--primary-color-dark);
    }

    &__links {
      a {
        color: var(--text-color-dark);

        &:hover {
          color: var(--primary-color-dark--hover);
        }
      }

      &.active {
        background-color: var(--primary-bg-dark-mode);
      }
    }

    &__profile {
      &-toggle {
        color: var(--text-color-dark);

        &:hover {
          color: var(--primary-color-dark--hover);
        }
      }

      &-menu {
        background-color: var(--primary-bg-dark-mode);

        a {
          color: var(--text-color-dark);

          &:hover {
            color: var(--primary-color-dark--hover);
          }
        }
      }
    }
  }

  @keyframes slideDown {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
.navbar__links a.bold {
  font-weight: bold;
}
.beta-text {
  margin-left: -2rem;
  margin-top: 0.5rem;
}